import React from 'react';
import { MoreVertical, Shield, Eye, Edit3 } from 'lucide-react';

const teamMembers = [
  {
    id: 1,
    name: 'Ana García',
    email: 'ana.garcia@empresa.com',
    role: 'Administrador',
    status: 'Activo',
    joinDate: '15 Mar 2024',
    avatar:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=150',
  },
  {
    id: 2,
    name: 'Carlos Rodríguez',
    email: 'carlos.rodriguez@empresa.com',
    role: 'Editor',
    status: 'Activo',
    joinDate: '10 Mar 2024',
    avatar:
      'https://images.unsplash.com/photo-1599566150163-29194dcaad36?w=150',
  },
  {
    id: 3,
    name: 'María López',
    email: 'maria.lopez@empresa.com',
    role: 'Visualizador',
    status: 'Pendiente',
    joinDate: '12 Mar 2024',
    avatar:
      'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=150',
  },
];

const getRoleIcon = (role) => {
  switch (role) {
    case 'Administrador':
      return <Shield className="h-4 w-4 text-purple-600" />;
    case 'Editor':
      return <Edit3 className="h-4 w-4 text-blue-600" />;
    case 'Visualizador':
      return <Eye className="h-4 w-4 text-gray-600" />;
    default:
      return null;
  }
};

const getStatusColor = (status) => {
  switch (status) {
    case 'Activo':
      return 'bg-green-100 text-green-800';
    case 'Pendiente':
      return 'bg-yellow-100 text-yellow-800';
    case 'Inactivo':
      return 'bg-gray-100 text-gray-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
};

export default function TeamMemberList({ searchTerm }) {
  const filteredMembers = teamMembers.filter(
    (member) =>
      member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      member.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Miembro
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Rol
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Estado
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Fecha de Ingreso
            </th>
            <th className="relative px-6 py-3">
              <span className="sr-only">Acciones</span>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {filteredMembers.map((member) => (
            <tr key={member.id} className="hover:bg-gray-50">
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                  <div className="h-10 w-10 flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full object-cover"
                      src={member.avatar}
                      alt={member.name}
                    />
                  </div>
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">
                      {member.name}
                    </div>
                    <div className="text-sm text-gray-500">{member.email}</div>
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center space-x-2">
                  {getRoleIcon(member.role)}
                  <span className="text-sm text-gray-900">{member.role}</span>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span
                  className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(member.status)}`}
                >
                  {member.status}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {member.joinDate}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button className="text-gray-400 hover:text-gray-600">
                  <MoreVertical className="h-5 w-5" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
