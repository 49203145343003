/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Components/Auth/Login';
import './index.css';
import Register from './Components/Auth/Register';
import RegisterSuccessful from './Components/Auth/RegisterSuccessful';
import { ContractCard } from './Components/Templates and Contracts/Contracts/ContractCard';
import TemplateCard from './Components/Templates and Contracts/Templates/TemplateCard';
import { ContractProvider } from './Context/contractContext';
import PaymentSuccessful from './Components/Templates and Contracts/Contracts/PaymentSuccessful';
import TermsAndConditions from './Components/Terms and Privacy/TermsAndConditions';
import PrivacyPolicy from './Components/Terms and Privacy/PrivacyPolicy';
import Footer from './Components/NewHome/Footer';
import UserProfile from './Components/User/UserProfile';
import AnalyticsRouter from './Components/Analytics/GoogleAnalytics';
import Dashboard from './Components/User/Dashboard';
import TemplatesContracts from './Components/Admin/TemplateContracts';
import NewHome from './Components/NewHome/Home';
import Navbar from './Components/NewHome/Navbar';
import ResetPassword from './Components/Auth/ResetPassword';
import ErrorPage from './Components/ErrorPage';

// Inicializa Google Analytics con tu ID de seguimiento
ReactGA.initialize('G-C6WVPC7N99'); // reemplaza con tu ID real
ReactGA.send('pageview'); // Enviar un pageview inicial

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Navbar />
      <AnalyticsRouter>
        <ContractProvider>
          <Routes>
            <Route path="/" element={<NewHome />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/register-success" element={<RegisterSuccessful />} />
            <Route path="/contract/:id/:title" element={<ContractCard />} />
            <Route path="/template/:id/:title" element={<TemplateCard />} />
            <Route
              path="/:contractName/payment-successful"
              element={<PaymentSuccessful />}
            />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </ContractProvider>
      </AnalyticsRouter>
      <Footer />
    </Router>
  </React.StrictMode>
);

reportWebVitals();
