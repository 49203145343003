/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ReactComponent as Contracts_Express } from '../../../assets/svg/Contratos_Express.svg';
import { ReactComponent as Vsg_AllRight } from '../../../assets/svg/Vector_AllRight.svg';
import './PaymentSuccessful.css';
const apiUrl = process.env.REACT_APP_API_URL;

const PaymentSuccessful = () => {
  const location = useLocation();
  const [dataTemplate, setDataTemplate] = useState({}); // Obtiene la data central del contrato
  const { contractName } = useParams();

  // Crear una instancia de URLSearchParams con la query string de la URL actual
  const queryParams = new URLSearchParams(location.search);

  // Obtener el valor del parámetro 'session_id'
  const sessionId = queryParams.get('session_id');

  const token = localStorage.getItem('authToken');

  useEffect(() => {
    if (token) {
      axios
        .put(
          `${apiUrl}/contracts/`,
          {
            session_id: sessionId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => setDataTemplate(res.data.contract));
    } else {
      axios
        .put(`${apiUrl}/contracts-without-register`, {
          session_id: sessionId,
        })
        .then((res) => setDataTemplate(res.data.contract));
    }
  }, []);

  const renderContract = () => {
    let renderedTemplate = dataTemplate.content;

    Object.keys(dataTemplate).forEach((variable) => {
      const regex = new RegExp(`\\[${variable}\\]`, 'g');
      renderedTemplate = renderedTemplate.replace(
        regex,
        `<span class="variable-highlight">${
          dataTemplate[variable] || `[${variable}]`
        }</span>`
      );
    });

    // Resaltar texto en mayúsculas
    renderedTemplate = renderedTemplate.replace(
      /\b(?!<span class="variable-highlight">)[A-ZÁÉÍÓÚÑÜ]{1,}\b(?!<\/span>)/g,
      (match) => `<span class="bold-text">${match}</span>`
    );

    return renderedTemplate;
  };

  const downloadContract = async () => {
    let response;
    try {
      if (token) {
        response = await axios.post(
          `${apiUrl}/payments/download-contract/`,
          {
            session_id: sessionId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',
          }
        );
      } else {
        response = await axios.post(
          `${apiUrl}/payments/download-contract-temporal/`,
          {
            session_id: sessionId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',
          }
        );
      }

      // Crear una URL para el blob para la descarga
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      console.log(response.data);

      link.href = url; // Genera una etiqueta con el url para cargar el pdf
      link.setAttribute('download', `${contractName}.pdf`); // Nombre del archivo que se descargará
      document.body.appendChild(link);
      link.click(); // Te redirige a otra pestaña para cargar el pdf.

      // Eliminar el elemento de la descarga
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading the contract:', error);
    }
  };

  return (
    <div className="flex flex-col items-center w-[full]">
      <div className="payment-Container successful w-[90%] flex justify-between bg-[#172b85] h-[25vh] mb-14 rounded-lg">
        <Vsg_AllRight className="payment_Logo_L h-[25vh]" />
        <section className="flex flex-col justify-evenly items-start">
          <div style={{ color: '#4CCCDE' }} className="">
            Tu documento esta listo
          </div>
          <div className="payment-Redirection">
            Puedes descargarlo haciendo{' '}
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                return downloadContract();
              }}
              className="text-white"
            >
              clic aquí
            </a>
          </div>
        </section>
        <Contracts_Express className="payment_Logo_R h-[30vh]" />
      </div>
      <div className="w-[90%] mx-auto">
        {dataTemplate.content && (
          <div className="contract-section">
            <div
              className="contract-content"
              dangerouslySetInnerHTML={{
                __html: renderContract().replace(/\n/g, '<br />'),
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentSuccessful;
