/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as WhatsApp } from '../../assets/conference/whatsapp.svg';
import UserMenu from './UserMenu';
import '../Home/Navbar.css';
import { jwtDecode } from 'jwt-decode';

const Navbar = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem('authToken');

  useEffect(() => {
    token ? setIsAuthenticated(token) : setIsAuthenticated(false);
    if (token !== null) {
      const getExpired = jwtDecode(token).exp * 1000;
      const nowDate = new Date().getTime();

      if (getExpired < nowDate) {
        setIsAuthenticated(false);
        localStorage.removeItem('authToken');
        navigate('/login');
      }
    }
  }, [isAuthenticated]);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
    navigate('/');
  };

  return (
    <header className="fixed w-full bg-white shadow-sm z-50">
      <div className="container mx-auto px-4 py-4">
        <div className="flex items-center justify-between">
          <button
            onClick={() => navigate('/')}
            className="flex items-center hover:opacity-80 transition-opacity group"
          >
            <div className="flex items-center justify-center w-10 h-10 rounded-xl bg-gradient-to-r from-primary-500 via-accent-purple to-accent-indigo text-white font-bold text-lg transform group-hover:scale-105 transition-transform">
              <span className="bg-gradient-to-r from-white/90 to-white bg-clip-text text-transparent">
                CX
              </span>
            </div>
            <div className="ml-3">
              <span className="text-xl font-bold bg-gradient-to-r from-primary-500 via-accent-purple to-accent-indigo bg-clip-text text-transparent h-[10vh] w-[10vw]">
                Contratos
              </span>
              <span className="text-xl font-bold text-accent-indigo ml-1">
                Express
              </span>
            </div>
          </button>
          <nav className="hidden md:flex items-center space-x-8">
            {isAuthenticated ? (
              <UserMenu onLogout={handleLogout} />
            ) : (
              <>
                <NavLink
                  to="/plans"
                  className="text-gray-600 hover:text-slate-600 transition opacity-60"
                  onClick={(e) => e.preventDefault()}
                >
                  Planes
                </NavLink>
                <NavLink
                  to="/login"
                  // className="text-gray-600 hover:text-blue-600 transition"
                  className="text-gray-600 hover:text-slate-600 transition opacity-60"
                  onClick={(e) => e.preventDefault()}
                >
                  Iniciar Sesión
                </NavLink>
                <NavLink
                  to="/register"
                  className="text-gray-600 hover:text-slate-600 transition opacity-60"
                  onClick={(e) => e.preventDefault()}
                  // className="text-gray-600 hover:text-blue-600 transition"
                >
                  Registrarse
                </NavLink>
                <a
                  href="https://wa.me/message/52YGG6B6M7W5C1"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center border border-green-500 text-green-500 px-6 py-2 rounded-lg hover:bg-green-50 transition"
                >
                  <WhatsApp className="h-5 w-5 mr-2" />
                  <p>Contáctanos</p>
                </a>
              </>
            )}
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
