/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Clock,
  CheckCircle,
  FileText,
  ChevronRight,
  CheckSquare,
  Square,
} from 'lucide-react';
import ContractEditor from './ContractEditor';
import ContractSearch from './ContractSearch';
import ContractTag from './ContractTag';
import TagSelector from './TagSelector';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

const initialContracts = {
  inProgress: [],
  completed: [],
};

export default function ContractsDashboard() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [editingContract, setEditingContract] = useState(null);
  const [contracts, setContracts] = useState(initialContracts);
  const token = localStorage.getItem('authToken');
  const [pendingChanges, setPendingChanges] = useState([]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/users/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // Actualiza el estado sin crear variables adicionales
        setContracts(() => ({
          inProgress: [
            ...res.data.contracts.filter(
              (contract) => contract.state === 'pending'
            ),
          ],
          completed: [
            ...res.data.contracts.filter(
              (contract) => contract.state === 'ending'
            ),
          ],
        }));
      })
      .catch((error) => {
        console.error('Error fetching contracts:', error);
      });

    return () => {};
  }, []);

  const handleEditContract = (contract) => {
    setEditingContract(contract);
  };

  const handleAddTag = (contractInfo, newTag) => {
    setContracts((prev) => {
      const updatedContracts = { ...prev };
      const contractsList =
        contractInfo.state === 'pending'
          ? updatedContracts.inProgress
          : updatedContracts.completed;

      contractsList.forEach((contract) => {
        if (
          contract.id === contractInfo.id &&
          !contract.tags.includes(newTag)
        ) {
          contract.tags.push(newTag);
        }
      });

      return updatedContracts;
    });

    setPendingChanges((prev) => {
      // Verificar si ya existe el mismo cambio en la cola
      const isDuplicate = prev.some(
        (change) =>
          change.contractId === contractInfo.id &&
          change.action === 'add' &&
          change.tag === newTag
      );

      if (isDuplicate) return prev; // No agregar duplicados

      // Eliminar cualquier acción "remove" previa para el mismo tag
      const updatedChanges = prev.filter(
        (change) =>
          !(
            change.contractId === contractInfo.id &&
            change.action === 'remove' &&
            change.tag === newTag
          )
      );

      return [
        ...updatedChanges,
        { contractId: contractInfo.id, action: 'add', tag: newTag },
      ];
    });
  };

  const handleRemoveTag = (contractInfo, tag) => {
    setContracts((prev) => {
      const updatedContracts = { ...prev };
      const contractsList =
        contractInfo.state === 'pending'
          ? updatedContracts.inProgress
          : updatedContracts.completed;

      contractsList.forEach((contract) => {
        if (contract.id === contractInfo.id) {
          contract.tags = contract.tags.filter((t) => t !== tag);
        }
      });

      return updatedContracts;
    });

    setPendingChanges((prev) => {
      // Verificar si ya existe el mismo cambio en la cola
      const isDuplicate = prev.some(
        (change) =>
          change.contractId === contractInfo.id &&
          change.action === 'remove' &&
          change.tag === tag
      );

      if (isDuplicate) return prev; // No agregar duplicados

      // Eliminar cualquier acción "add" previa para el mismo tag
      const updatedChanges = prev.filter(
        (change) =>
          !(
            change.contractId === contractInfo.id &&
            change.action === 'add' &&
            change.tag === tag
          )
      );

      return [
        ...updatedChanges,
        { contractId: contractInfo.id, action: 'remove', tag },
      ];
    });
  };

  const useDebouncedEffect = (callback, delay, deps) => {
    useEffect(() => {
      const handler = setTimeout(() => callback(), delay);
      return () => clearTimeout(handler);
    }, [...deps, delay]);
  };

  // Enviar los cambios pendientes al backend
  const sendPendingChanges = async () => {
    if (pendingChanges.length === 0) return;

    try {
      const response = await fetch(`${apiUrl}/contracts/tags`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ changes: pendingChanges }),
      });

      if (!response.ok) throw new Error('Error al enviar cambios');
      console.log('Cambios enviados con éxito:', pendingChanges);
      setPendingChanges([]); // Limpiar cambios después del envío
    } catch (error) {
      console.error('Error al enviar cambios:', error);
      // Manejar reintentos si es necesario
    }
  };

  // Ejecutar el debounce cada vez que cambian los cambios pendientes
  useDebouncedEffect(sendPendingChanges, 3000, [pendingChanges]);

  const handleSelectContract = (contractId) => {
    setSelectedContracts((prev) =>
      prev.includes(contractId)
        ? prev.filter((id) => id !== contractId)
        : [...prev, contractId]
    );
  };

  if (editingContract) {
    return (
      <ContractEditor
        contract={editingContract}
        onClose={() => setEditingContract(null)}
      />
    );
  }

  return (
    <div className="space-y-6">
      {/* Header Stats */}
      <div className="grid grid-cols-3 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-500">En Progreso</p>
              <p className="text-2xl font-bold text-gray-900">
                {contracts.inProgress.length}
              </p>
            </div>
            <Clock className="h-8 w-8 text-blue-500" />
          </div>
        </div>
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-500">Lista de Contratos</p>
              <p className="text-2xl font-bold text-gray-900">
                {contracts.completed.length}
              </p>
            </div>
            <CheckCircle className="h-8 w-8 text-emerald-500" />
          </div>
        </div>
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-500">Total</p>
              <p className="text-2xl font-bold text-gray-900">
                {contracts.inProgress.length + contracts.completed.length}
              </p>
            </div>
            <FileText className="h-8 w-8 text-purple-500" />
          </div>
        </div>
      </div>

      {/* Search and Filters */}
      <ContractSearch
        searchTerm={searchTerm}
        onSearchChange={setSearchTerm}
        selectedFilter={selectedFilter}
        onFilterChange={setSelectedFilter}
      />

      {/* In Progress Contracts */}
      <div className="space-y-6">
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">
            En Progreso
          </h2>
          <div className="space-y-4">
            {contracts.inProgress.map((contract) => (
              <div
                key={contract.id}
                className="border border-gray-200 rounded-lg p-4"
              >
                <div className="flex items-center justify-between mb-3">
                  <div className="flex items-center space-x-3">
                    <button
                      onClick={() => handleSelectContract(contract.id)}
                      className="text-gray-400 hover:text-blue-600"
                    >
                      {selectedContracts.includes(contract.id) ? (
                        <CheckSquare className="h-5 w-5" />
                      ) : (
                        <Square className="h-5 w-5" />
                      )}
                    </button>
                    <div>
                      <h3 className="font-medium text-gray-900">
                        {contract.name}
                      </h3>
                      <p className="text-sm text-gray-500">
                        Última edición:{' '}
                        {`${contract.updatedAt.slice(0, 10)} ${''} ${contract.updatedAt.slice(11, 19)}`}
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => handleEditContract(contract)}
                    className="text-blue-600 hover:text-blue-700 flex items-center"
                  >
                    Continuar <ChevronRight className="h-4 w-4 ml-1" />
                  </button>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex space-x-2">
                    {contract.tags.map((tag, index) => (
                      <ContractTag
                        key={index}
                        tag={tag}
                        contract={contract}
                        handleRemoveTag={handleRemoveTag}
                      />
                    ))}
                  </div>
                  <TagSelector
                    contract={contract}
                    handleAddTag={handleAddTag}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Completed Contracts */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">
            Completados
          </h2>
          <div className="space-y-4">
            {contracts.completed.map((contract) => (
              <div
                key={contract.id}
                className="border border-gray-200 rounded-lg p-4"
              >
                <div className="flex items-center justify-between mb-3">
                  <div className="flex items-center space-x-3">
                    <CheckCircle className="h-5 w-5 text-green-500" />
                    <div>
                      <h3 className="font-medium text-gray-900">
                        {contract.name}
                      </h3>
                      <p className="text-sm text-gray-500">
                        Completado el:{' '}
                        {`${contract.updatedAt.slice(0, 10)} ${''} ${contract.updatedAt.slice(11, 19)}`}
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => handleEditContract(contract)}
                    className="text-blue-600 hover:text-blue-700 flex items-center"
                  >
                    Ver detalles <ChevronRight className="h-4 w-4 ml-1" />
                  </button>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex space-x-2">
                    {contract.tags.map((tag, index) => (
                      <ContractTag
                        key={index}
                        tag={tag}
                        contract={contract}
                        handleRemoveTag={handleRemoveTag}
                      />
                    ))}
                  </div>
                  <TagSelector
                    contract={contract}
                    handleAddTag={handleAddTag}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
