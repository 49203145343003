import React, { useState, useRef } from 'react';
import { Upload, MessageCircle, X, File, Download } from 'lucide-react';
import SignatureCanvas from './SignatureCanvas';

export default function SignDocumentsDashboard() {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [uploadedDoc, setUploadedDoc] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [signature, setSignature] = useState(null);
  const fileInputRef = useRef(null);

  const handleWhatsAppShare = () => {
    if (!phoneNumber.trim()) {
      alert('Por favor, ingresa un número de teléfono válido');
      return;
    }

    const formattedPhone = phoneNumber.replace(/\D/g, '');
    const message = encodeURIComponent(
      `Te comparto el documento "${selectedDocument}" para firma electrónica. Por favor, revisa y firma el documento siguiendo el enlace.`
    );
    const whatsappUrl = `https://wa.me/${formattedPhone}?text=${message}`;

    window.open(whatsappUrl, '_blank');
  };

  const handleFileSelect = (file) => {
    if (!file) return;

    const allowedTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'image/jpeg',
      'image/png',
    ];
    if (!allowedTypes.includes(file.type)) {
      alert('Por favor, sube un archivo PDF, DOCX o imagen (JPG/PNG)');
      return;
    }

    if (file.size > 10 * 1024 * 1024) {
      alert('El archivo no debe superar los 10MB');
      return;
    }

    const preview = URL.createObjectURL(file);
    const size = formatFileSize(file.size);

    setUploadedDoc({
      file,
      preview,
      name: file.name,
      size,
      type: file.type,
    });

    setSelectedDocument(file.name);
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    handleFileSelect(file);
  };

  const removeUploadedDoc = () => {
    if (uploadedDoc?.preview) {
      URL.revokeObjectURL(uploadedDoc.preview);
    }
    setUploadedDoc(null);
    setSelectedDocument(null);
    setSignature(null);
  };

  const handleSignatureSave = (signatureData) => {
    setSignature(signatureData);
    // Aquí normalmente enviarías la firma al backend para procesarla
  };

  const handleSignedDownload = () => {
    if (!uploadedDoc || !signature) return;

    alert(
      'Documento firmado exitosamente. En un sistema real, aquí se descargaría el documento firmado.'
    );
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">
          Subir Documento para Firmar
        </h2>
        {uploadedDoc ? (
          <div className="space-y-4">
            <div className="flex items-start justify-between p-4 border border-gray-200 rounded-lg">
              <div className="flex items-center space-x-3">
                <div className="p-2 bg-blue-50 rounded-lg">
                  <File className="h-6 w-6 text-blue-500" />
                </div>
                <div>
                  <h3 className="font-medium text-gray-900">
                    {uploadedDoc.name}
                  </h3>
                  <p className="text-sm text-gray-500">{uploadedDoc.size}</p>
                </div>
              </div>
              <button
                onClick={removeUploadedDoc}
                className="text-gray-400 hover:text-gray-600"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            {uploadedDoc.type === 'application/pdf' && (
              <div className="border border-gray-200 rounded-lg">
                <iframe
                  src={uploadedDoc.preview}
                  className="w-full h-96 rounded-lg"
                  title="Vista previa del documento"
                />
              </div>
            )}

            <SignatureCanvas onSave={handleSignatureSave} />

            {signature && (
              <div className="mt-4">
                <button
                  onClick={handleSignedDownload}
                  className="w-full bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition-colors flex items-center justify-center"
                >
                  <Download className="h-5 w-5 mr-2" />
                  Descargar Documento Firmado
                </button>
              </div>
            )}
          </div>
        ) : (
          <div
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className={`border-2 border-dashed rounded-lg p-8 text-center transition-colors ${
              isDragging
                ? 'border-blue-500 bg-blue-50'
                : 'border-gray-300 hover:border-blue-500 hover:bg-gray-50'
            }`}
          >
            <input
              type="file"
              ref={fileInputRef}
              onChange={(e) =>
                e.target.files && handleFileSelect(e.target.files[0])
              }
              className="hidden"
              accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
            />
            <Upload className="h-12 w-12 mx-auto text-gray-400 mb-4" />
            <p className="text-gray-600 mb-2">
              Arrastra un archivo o haz clic para seleccionar
            </p>
            <p className="text-sm text-gray-500 mb-4">
              PDF, DOCX o JPG (max. 10MB)
            </p>
            <button
              onClick={() => fileInputRef.current?.click()}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
            >
              Seleccionar Archivo
            </button>
          </div>
        )}
      </div>

      {uploadedDoc && (
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">
            Compartir para Firma
          </h2>
          <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
            <div className="flex items-center">
              <MessageCircle className="h-5 w-5 text-gray-400 mr-2" />
              <div>
                <p className="font-medium text-gray-900">WhatsApp</p>
                <p className="text-sm text-gray-500">Enviar por WhatsApp</p>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="tel"
                placeholder="+52 1234567890"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="px-3 py-1 border border-gray-300 rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
              <button
                onClick={handleWhatsAppShare}
                className="bg-green-500 text-white px-3 py-1 rounded-lg hover:bg-green-600 transition-colors text-sm flex items-center"
              >
                <MessageCircle className="h-4 w-4 mr-1" />
                Enviar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
