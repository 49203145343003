import React from 'react';
import { Tag, X } from 'lucide-react';

export default function ContractTag({ tag, contract, handleRemoveTag }) {
  let tagClasses = 'bg-gray-100 text-gray-800 border-gray-200'; // Valor predeterminado

  if (tag.toLowerCase() === 'pendiente de firma') {
    tagClasses = 'bg-yellow-100 text-yellow-800 border-yellow-200';
  } else if (tag.toLowerCase() === 'revisión legal') {
    tagClasses = 'bg-purple-100 text-purple-800 border-purple-200';
  } else if (tag.toLowerCase() === 'urgente') {
    tagClasses = 'bg-red-100 text-red-800 border-red-200';
  } else if (tag.toLowerCase() === 'en proceso') {
    tagClasses = 'bg-blue-100 text-blue-800 border-blue-200';
  } else if (tag.toLowerCase() === 'completado') {
    tagClasses = 'bg-green-100 text-green-800 border-green-200';
  }

  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border ${tagClasses}`}
    >
      <Tag className="w-3 h-3 mr-1" />
      {tag}
      {handleRemoveTag && (
        <button
          onClick={() => {
            handleRemoveTag(contract, tag);
          }}
          className="ml-1 hover:text-gray-600 focus:outline-none"
        >
          <X className="w-3 h-3" />
        </button>
      )}
    </span>
  );
}
