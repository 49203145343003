import React from 'react';
import './RegisterSuccessful.css';
import { Link } from 'react-router-dom';

const RegisterSuccessful = () => {
  return (
    <div>
      <div className="confirmation-wrapper">
        <div className="confirmation-container">
          <h1>Registration Successful!</h1>
          <p>
            Your account has been successfully created. You can now log in using
            your credentials.
          </p>
          <Link className="link" to="/login">
            Go to Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RegisterSuccessful;
