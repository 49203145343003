/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './ContractCard.css';
import { ReactComponent as Contracts_Express } from '../../../assets/svg/Contratos_Express.svg';
import { ReactComponent as Vsg_AllRight } from '../../../assets/svg/Vector_AllRight.svg';
import Calendar from './Calendar';
import { States, Validity } from '../../../Stateful/Validity';
const apiUrl = process.env.REACT_APP_API_URL;
const apiK_Stripe = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export const ContractCard = () => {
  const stripePromise = loadStripe(apiK_Stripe);
  const { id } = useParams();
  const [dataTemplate, setDataTemplate] = useState({}); // Obtiene la data central del contrato
  const [variables, setVariables] = useState([]); // Contiene las variables del contrato "[variable]"
  const [contractVariables, setContractVariables] = useState({
    datesAndSignatures: {
      ['fecha actual']: new Date(),
    },
  }); // Crea un obj reactivo con las variables extraidas
  const [controllator, setControllator] = useState(1); // Controla el estado visual de pasos para llenar los inputs
  const [selectedOption, setSelectedOption] = useState('yes'); // Estado para controlar el checkbox de clausulas

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    const fetchContractTemplate = async () => {
      try {
        const contractTemplate = await axios.get(`${apiUrl}/templates/${id}`);
        const templateObtained = contractTemplate.data;
        setDataTemplate(templateObtained);

        if (templateObtained.content) {
          const extractedVariables = [
            ...new Set(
              templateObtained.content
                .match(/\[([^\]]+)\]/g)
                .map((v) => v.slice(1, -1))
            ),
          ];
          setVariables(extractedVariables);

          // Organize variables por categoria
          const varFiltredUser1 = extractedVariables.filter((v) =>
            v.includes('1')
          );
          const varFiltredUser2 = extractedVariables.filter((v) =>
            v.includes('2')
          );
          const clausesVars = extractedVariables.filter((v) =>
            v.includes('Cláusula')
          );
          const datesAndSignaturesVars = extractedVariables.filter(
            (v) =>
              v.includes('Fecha ') ||
              v.includes('fecha ') ||
              v.includes('FECHA ') ||
              v.includes('Fecha de inicio') ||
              v.includes('Fecha final') ||
              v.includes('firma ') ||
              v.includes('FIRMA ') ||
              v.includes('vigencia ') ||
              v.includes('VIGENCIA ') ||
              v.includes('ENTIDAD FEDERATIVA ') ||
              v.includes('Entidad Federativa') ||
              v.includes('OPCION')
          );

          const initialVariables = {
            persona1: varFiltredUser1.reduce((acc, variable) => {
              acc[variable] = '';
              return acc;
            }, {}),
            persona2: varFiltredUser2.reduce((acc, variable) => {
              acc[variable] = '';
              return acc;
            }, {}),
            clauses: clausesVars.reduce((acc, variable) => {
              acc[variable] = '';
              return acc;
            }, {}),
            datesAndSignatures: datesAndSignaturesVars.reduce(
              (acc, variable) => {
                if (variable.includes('OPCION')) {
                  const options =
                    variable
                      .slice(7)
                      .match(/\{([^}]+)\}/g)
                      ?.map((match) => match.slice(1, -1)) || [];

                  acc[variable.slice(0, 6)] = { optionSelected: '', options };
                } else {
                  acc[variable] = ''; // Mantiene el sistema de variables con valores vacíos si no son OPCION
                }
                return acc;
              },
              {}
            ),
          };
          setContractVariables(initialVariables);
        }
      } catch (error) {
        console.error('Error fetching contract template obtained:', error);
      }
    };
    fetchContractTemplate();
  }, [id]);

  const renderContract = () => {
    let renderedTemplate = dataTemplate.content;

    // Función que extrae el bloque de opciones y lo reemplaza con una variable [OPCION]
    function cleanOptionBlocks(renderedTemplate) {
      const optionBlockRegex = /\[OPCION:\s*\{.*?\}\s*\]/gs;
      return renderedTemplate.replace(optionBlockRegex, '[OPCION]'); // Se deja "[OPCION]" como marcador
    }

    let contractFilterOptions = cleanOptionBlocks(renderedTemplate);

    // Reemplazar las variables en todo el contrato
    Object.keys(contractVariables).forEach((category) => {
      Object.keys(contractVariables[category]).forEach((variable) => {
        const value = contractVariables[category][variable];

        let displayValue = '';
        if (typeof value === 'object' && value !== null) {
          // Caso 1: Si es un objeto con "optionSelected" y "options"
          if (value.optionSelected && Array.isArray(value.options)) {
            displayValue = value.optionSelected; // Solo mostrar la opción seleccionada
          } else {
            displayValue = '[Seleccionar opción]'; // Si no hay opción seleccionada, mostrar esto
          }
        } else {
          // Si es un valor simple, lo dejamos como está
          displayValue = value || `[${variable}]`;
        }

        const regex = new RegExp(`\\[${variable}\\]`, 'g');
        contractFilterOptions = contractFilterOptions.replace(
          regex,
          `<span class="variable-highlight">${displayValue}</span>`
        );
      });
    });

    // Otras manipulaciones o censuras en el contrato
    const startCensorship1 = 'SEGUNDA. DEFINICIONES';
    const endCensorship1 =
      'Confidencial para los fines establecidos en este Convenio';

    const startIdx1 = contractFilterOptions.indexOf(startCensorship1);
    const endIdx1 = contractFilterOptions.indexOf(
      endCensorship1,
      startIdx1 + startCensorship1.length
    );

    if (startIdx1 !== -1 && endIdx1 !== -1) {
      const beforeCensorship1 = contractFilterOptions.slice(0, startIdx1);
      const toCensor1 = contractFilterOptions.slice(
        startIdx1,
        endIdx1 + endCensorship1.length
      );
      const afterCensorship1 = contractFilterOptions.slice(
        endIdx1 + endCensorship1.length
      );

      const censoredText1 = `<span class="blurred">${toCensor1}</span>`;
      contractFilterOptions = `${beforeCensorship1}${censoredText1}${afterCensorship1}`;
    }

    // Aplicar censura para el segundo bloque
    const startCensorship2 = 'Independientemente de lo establecido';
    const endCensorship2 =
      'reclamar a su contraparte, compensación, contraprestación o gasto alguno.';

    const startIdx2 = contractFilterOptions.indexOf(startCensorship2);
    const endIdx2 = contractFilterOptions.indexOf(
      endCensorship2,
      startIdx2 + startCensorship2.length
    );

    if (startIdx2 !== -1 && endIdx2 !== -1) {
      const beforeCensorship2 = contractFilterOptions.slice(0, startIdx2);
      const toCensor2 = contractFilterOptions.slice(
        startIdx2,
        endIdx2 + endCensorship2.length
      );
      const afterCensorship2 = contractFilterOptions.slice(
        endIdx2 + endCensorship2.length
      );

      const censoredText2 = `<span class="blurred">${toCensor2}</span>`;
      contractFilterOptions = `${beforeCensorship2}${censoredText2}${afterCensorship2}`;
    }

    // Resaltar texto en mayúsculas
    contractFilterOptions = contractFilterOptions.replace(
      /\b(?!<span class="variable-highlight">)[A-ZÁÉÍÓÚÑÜ]{1,}\b(?!<\/span>)/g,
      (match) => `<span class="bold-text">${match}</span>`
    );

    // Formatear la sección de firmas
    contractFilterOptions = formatFirmsSection(contractFilterOptions);

    return contractFilterOptions;
  };

  // Función para formatear la sección de firmas
  function formatFirmsSection(content) {
    const lines = content.split('\n');

    const formattedLines = lines.map((line) => {
      // Si la línea contiene una firma (guiones), aplicar formato especial
      if (line.includes('___________________')) {
        // Devolver las firmas formateadas en una tabla HTML
        return `
        <div style="display: flex;  justify-content: space-between; padding-left: 0px; padding-right: 0px;">
          <div  style="display: flex; flex-direction: column; padding: 0px; height: 20vh; justify-content: space-around; align-items: center;">
            ${line.split('                                               ')[0]}<br>
          </div>
        </div>
      `;
      }

      // Si no es una línea de firma, devolver la línea tal cual
      return line;
    });

    const firm1 = formattedLines[formattedLines.length - 1],
      firm2 = formattedLines[formattedLines.length - 2]; // Selecciona las 2 secciones de firmas al final de cada contrato

    formattedLines.pop();
    formattedLines.pop(); // elimina las 2 secciones de firma sin manipular del arreglo del contrato principal

    const sectionFirmSuccess = [
      `<div style="display: flex;  justify-content: space-between; padding: 0px;">
          ${firm1} ${firm2}
        </div>`,
    ];

    formattedLines.push(sectionFirmSuccess); // Agrega la seccion de firmas manipulada y lista visualmente para renderizar al arreglo del contrato principal

    return formattedLines.join('\n');
  }

  const extractTextFromHtml = (html) => {
    const temporaryElement = document.createElement('div');
    temporaryElement.innerHTML = html;
    return temporaryElement.innerText || temporaryElement.textContent || '';
  };

  const handleCheckout = async () => {
    const stripe = await stripePromise;
    const numWithoutDot = Math.floor(parseFloat(dataTemplate.price) * 100);
    const token = localStorage.getItem('authToken');

    try {
      const htmlContract = renderContract();
      const plainTextContract = extractTextFromHtml(htmlContract);
      let createContractResponse;
      let createContractTemporalResponse;
      const paymentResponse = await axios.post(
        `${apiUrl}/payments/create-payment`,
        { name: dataTemplate.name, price: numWithoutDot },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      token
        ? (createContractResponse = await axios.post(
            `${apiUrl}/contracts/`,
            {
              session_id: paymentResponse.data.id,
              contractTemplateId: dataTemplate.id,
              name: dataTemplate.name,
              content: plainTextContract,
              type: dataTemplate.type,
              price: dataTemplate.price,
              paymentIntentId: null,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          ))
        : (createContractTemporalResponse = await axios.post(
            `${apiUrl}/contracts-without-register/`,
            {
              session_id: paymentResponse.data.id,
              contractTemplateId: dataTemplate.id,
              name: dataTemplate.name,
              content: plainTextContract,
              type: dataTemplate.type,
              price: dataTemplate.price,
              paymentIntentId: null,
            }
          ));

      if (
        (paymentResponse && createContractResponse) ||
        (paymentResponse && createContractTemporalResponse)
      ) {
        const result = await stripe.redirectToCheckout({
          sessionId: paymentResponse.data.id,
        });

        if (result.error) {
          console.error(result.error.message);
        }
      }
    } catch (error) {
      console.error('Error during checkout:', error);
    }
  };

  return (
    <section className="">
      <header className="header-Container w-[90%] mx-auto m-0 p-6 pt-[7rem] pb-8">
        <h1 className="pb-6 gradient-text font-[600] text-2xl">{`Edición de documentos - ${dataTemplate.name}`}</h1>
        <section className="flex justify-between">
          {[
            `Datos de la Persona 1`,
            'Datos de la Persona 2',
            // 'Cláusulas',
            'Firmas y Fechas',
            'Pago y Descarga',
          ].map((text, index) => (
            <div
              key={index}
              style={{
                backgroundColor:
                  controllator === index + 1 ? 'rgb(37 99 235)' : '#B0B0B0',
                width: controllator === index + 1 ? '35%' : '25%',
              }}
              className={`card-Controll-Title w-[20%] h-[9vh] text-white content-center text-center font-semibold ${index === 0 ? 'rounded-l' : index === 4 ? 'rounded-r' : ''}`}
            >
              {index + 1}.- {text}
            </div>
          ))}
        </section>
      </header>
      <div className="contract-Container pt-0">
        <section className="controller-Container flex flex-col items-end">
          <div className="controlator w-full">
            {controllator === 1 && (
              <>
                <h4 className="text-primary-custom-text font-[600]">{`Escribe tus datos aquí`}</h4>
                <section className="grid grid-cols-1 md:grid-cols-2 gap-x-4 w-[100%] mx-auto">
                  {Object.keys(contractVariables.persona1 || {}).map(
                    (variable, index) =>
                      variable.includes('vigencia') ||
                      variable.includes('VIGENCIA') ? (
                        <div className="form-group" key={index}>
                          <label
                            htmlFor="duracion"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {variable}:
                          </label>
                          <select
                            id="duracion"
                            name="duracion"
                            onChange={(e) =>
                              setContractVariables({
                                ...contractVariables,
                                datesAndSignatures: {
                                  ...contractVariables.datesAndSignatures,
                                  [variable]: `${e.target.value}`,
                                },
                              })
                            }
                            value={
                              contractVariables.datesAndSignatures[
                                'PERIODO DE VIGENCIA'
                              ]
                            }
                          >
                            <option value={`PERIODO DE VIGENCIA ${' '}`}>
                              INSERTA EL PERIODO DE VIGENCIA
                            </option>
                            {Validity.map((opt, index) => {
                              return (
                                <option key={index} value={`${opt} ${' '}`}>
                                  {opt}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      ) : variable.includes('ENTIDAD FEDERATIVA') ? (
                        <div className="form-group" key={index}>
                          <label
                            htmlFor="estado"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {variable}:
                          </label>
                          <select
                            id="estado"
                            name="estado"
                            onChange={(e) =>
                              setContractVariables({
                                ...contractVariables,
                                datesAndSignatures: {
                                  ...contractVariables.datesAndSignatures,
                                  [variable]: `${e.target.value}`,
                                },
                              })
                            }
                          >
                            <option value={`ENTIDAD FEDERATIVA ${' '}`}>
                              INSERTA ENTIDAD FEDERATIVA 1
                            </option>
                            {States.map((state, i) => {
                              return (
                                <option value={state} key={i}>
                                  {state}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      ) : variable.includes('FECHA') ? (
                        <Calendar
                          variable={variable}
                          setContractVariables={setContractVariables}
                          contractVariables={contractVariables}
                          key={index}
                        />
                      ) : !variable.includes('OPCION') ? (
                        <div key={index} className="form-group mx-auto">
                          <label>{variable.slice(0, -1)}:</label>
                          <input
                            type="text"
                            value={contractVariables.persona1[variable]}
                            placeholder={
                              variable.includes('DOMICILIO')
                                ? 'Calle Vicente #243'
                                : `Inserte ${variable.slice(0, -1)}`
                            }
                            onChange={(e) => {
                              if (
                                e.target.attributes[2].value.includes(
                                  'NOMBRE'
                                ) ||
                                e.target.attributes[2].value.includes('INE')
                              ) {
                                return setContractVariables({
                                  ...contractVariables,
                                  persona1: {
                                    ...contractVariables.persona1,
                                    [variable]:
                                      e.target.value.toLocaleUpperCase(),
                                  },
                                });
                              }
                              setContractVariables({
                                ...contractVariables,
                                persona1: {
                                  ...contractVariables.persona1,
                                  [variable]: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                      ) : (
                        false
                      )
                  )}
                </section>
              </>
            )}
            {controllator === 2 && (
              <>
                <h4 className="text-primary-custom-text font-[600]">{`Escribe tus datos aquí`}</h4>
                <section className="grid grid-cols-1 md:grid-cols-2 gap-x-4 w-[100%] mx-auto">
                  {Object.keys(contractVariables.persona2 || {}).map(
                    (variable, index) =>
                      variable.includes('vigencia') ||
                      variable.includes('VIGENCIA') ? (
                        <div className="form-group" key={index}>
                          <label
                            htmlFor="duracion"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {variable}:
                          </label>
                          <select
                            id="duracion"
                            name="duracion"
                            onChange={(e) =>
                              setContractVariables({
                                ...contractVariables,
                                datesAndSignatures: {
                                  ...contractVariables.datesAndSignatures,
                                  [variable]: `${e.target.value}`,
                                },
                              })
                            }
                            value={
                              contractVariables.datesAndSignatures[
                                'PERIODO DE VIGENCIA'
                              ]
                            }
                          >
                            <option value={`PERIODO DE VIGENCIA ${' '}`}>
                              INSERTA EL PERIODO DE VIGENCIA
                            </option>
                            {Validity.map((opt, index) => {
                              return (
                                <option key={index} value={`${opt} ${' '}`}>
                                  {opt}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      ) : variable.includes('ENTIDAD FEDERATIVA') ? (
                        <div className="form-group" key={index}>
                          <label
                            htmlFor="estado"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {variable}:
                          </label>
                          <select
                            id="estado"
                            name="estado"
                            onChange={(e) =>
                              setContractVariables({
                                ...contractVariables,
                                datesAndSignatures: {
                                  ...contractVariables.datesAndSignatures,
                                  [variable]: `${e.target.value}`,
                                },
                              })
                            }
                          >
                            <option value={`ENTIDAD FEDERATIVA ${' '}`}>
                              INSERTA ENTIDAD FEDERATIVA 2
                            </option>
                            {States.map((state, i) => {
                              return (
                                <option value={state} key={i}>
                                  {state}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      ) : variable.includes('FECHA') ? (
                        <Calendar
                          variable={variable}
                          setContractVariables={setContractVariables}
                          contractVariables={contractVariables}
                          key={index}
                        />
                      ) : !variable.includes('OPCION') ? (
                        <div key={index} className="form-group mx-auto">
                          <label>{variable.slice(0, -1)}:</label>
                          <input
                            type="text"
                            value={contractVariables.persona1[variable]}
                            placeholder={
                              variable.includes('DOMICILIO')
                                ? 'Calle Vicente #243'
                                : `Inserte ${variable.slice(0, -1)}`
                            }
                            onChange={(e) => {
                              if (
                                e.target.attributes[2].value.includes(
                                  'NOMBRE'
                                ) ||
                                e.target.attributes[2].value.includes('INE')
                              ) {
                                return setContractVariables({
                                  ...contractVariables,
                                  persona1: {
                                    ...contractVariables.persona1,
                                    [variable]:
                                      e.target.value.toLocaleUpperCase(),
                                  },
                                });
                              }
                              setContractVariables({
                                ...contractVariables,
                                persona1: {
                                  ...contractVariables.persona1,
                                  [variable]: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                      ) : (
                        false
                      )
                  )}
                </section>
              </>
            )}
            {/* {controllator === 3 && (
              <>
                <section className="form-clauses">
                  <div className="flex flex-col">
                    <h4 className="text-primary-custom-text font-[600]">
                      Editar Cláusulas
                    </h4>
                    <section className="section-edit-clauses w-[40%] mx-auto flex justify-between items-start">
                      <div className="clauses-label flex flex-col items-center w-[50%]">
                        <p>Cláusulas predeterminadas</p>
                        <section className="flex">
                          <label>
                            <input
                              disabled
                              type="checkbox"
                              checked={selectedOption === 'yes'}
                              onChange={() => setSelectedOption('yes')}
                            />
                            Sí
                          </label>

                          <label style={{ marginLeft: '20px' }}>
                            <input
                              disabled
                              type="checkbox"
                              checked={selectedOption === 'no'}
                              onChange={() => setSelectedOption('no')}
                            />
                            No
                          </label>
                        </section>
                      </div>
                      <div className="button-edit-clauses w-[40%]">
                        <button
                          className="w-full rounded-lg h-[6vh] bg-[#f1f1f1] shadow-[0_6px_10px_0px_rgba(176,176,176,0.69)] border-none"
                          disabled
                        >
                          Editar Cláusulas
                        </button>
                      </div>
                    </section>
                  </div>
                </section>
              </>
            )} */}
            {controllator === 3 && (
              <>
                <h4 className="text-primary-custom-text font-[600]">{`Escribe tus datos aquí`}</h4>
                <section className="form-section">
                  {Object.keys(contractVariables.datesAndSignatures || {}).map(
                    (variable, index) =>
                      variable.includes('vigencia') ||
                      variable.includes('VIGENCIA') ? (
                        <div className="form-group" key={index}>
                          <label
                            htmlFor="duracion"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {variable}:
                          </label>
                          <select
                            id="duracion"
                            name="duracion"
                            onChange={(e) =>
                              setContractVariables({
                                ...contractVariables,
                                datesAndSignatures: {
                                  ...contractVariables.datesAndSignatures,
                                  [variable]: `${e.target.value}`,
                                },
                              })
                            }
                            value={
                              contractVariables.datesAndSignatures[
                                'PERIODO DE VIGENCIA'
                              ]
                            }
                          >
                            <option value={`PERIODO DE VIGENCIA ${' '}`}>
                              INSERTA EL PERIODO DE VIGENCIA
                            </option>
                            {Validity.map((opt, index) => {
                              return (
                                <option key={index} value={`${opt} ${' '}`}>
                                  {opt}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      ) : variable.includes('ENTIDAD FEDERATIVA') ||
                        (variable.includes('Entidad Federativa') &&
                          !/\d/.test(variable)) ? (
                        <div className="form-group" key={index}>
                          <label
                            htmlFor="estado"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {variable}:
                          </label>
                          <select
                            id="estado"
                            name="estado"
                            onChange={(e) =>
                              setContractVariables({
                                ...contractVariables,
                                datesAndSignatures: {
                                  ...contractVariables.datesAndSignatures,
                                  [variable]: `${e.target.value}`,
                                },
                              })
                            }
                          >
                            {States.map((state, i) => {
                              return (
                                <option value={state} key={i}>
                                  {state}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      ) : variable.includes('FECHA') ||
                        (variable.includes('Fecha') && !/\d/.test(variable)) ? (
                        <Calendar
                          variable={variable}
                          setContractVariables={setContractVariables}
                          contractVariables={contractVariables}
                          key={index}
                        />
                      ) : (
                        variable.includes('OPCION') && (
                          <div className="form-group" key={index}>
                            <label
                              htmlFor={variable}
                              className="block text-sm font-medium text-gray-700"
                            >
                              {variable}:
                            </label>
                            <select
                              id={variable}
                              name={variable}
                              value={
                                contractVariables.datesAndSignatures[variable]
                                  .optionSelected
                              }
                              onChange={(e) => {
                                const selectedOption = e.target.value;
                                setContractVariables((prevState) => ({
                                  ...prevState,
                                  datesAndSignatures: {
                                    ...prevState.datesAndSignatures,
                                    [variable]: {
                                      ...prevState.datesAndSignatures[variable],
                                      optionSelected: selectedOption,
                                    },
                                  },
                                }));
                              }}
                            >
                              <option value="">Selecciona una opción</option>
                              {contractVariables.datesAndSignatures[
                                variable
                              ].options.map((option, optionIndex) => (
                                <option key={optionIndex} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                            {contractVariables.datesAndSignatures['OPCION'] && (
                              <input
                                placeholder="Inserte SU OPCION PERSONALIZADA"
                                type="text"
                                name={variable}
                                id={variable}
                                value={
                                  contractVariables.datesAndSignatures['OPCION']
                                    .optionSelected
                                }
                                onChange={(e) => {
                                  setContractVariables((prevState) => ({
                                    ...prevState,
                                    datesAndSignatures: {
                                      ...prevState.datesAndSignatures,
                                      [variable]: {
                                        ...prevState.datesAndSignatures[
                                          variable
                                        ],
                                        optionSelected: e.target.value,
                                      },
                                    },
                                  }));
                                }}
                              />
                            )}
                          </div>
                        )
                      )
                  )}
                </section>
              </>
            )}
            {controllator === 4 && (
              <div className="payment-Container flex justify-between bg-primary-500 h-[20vh] mb-14 rounded-lg">
                <Vsg_AllRight className="payment_Logo_L h-[20vh]" />
                <section className="flex flex-col justify-evenly items-start">
                  <div style={{ color: '#4CCCDE' }} className="">
                    Tu documento esta casi listo
                  </div>
                  <div className="payment-Redirection">
                    Podrás descargarlo despúes de{' '}
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        return handleCheckout();
                      }}
                      className="text-white"
                    >
                      completar tu compra
                    </a>
                  </div>
                </section>
                <Contracts_Express className="payment_Logo_R h-[20vh]" />
              </div>
            )}
          </div>
          <section className="Buttons-Container flex justify-evenly w-[30%] pb-2">
            <button
              onClick={() =>
                controllator > 1 && setControllator(controllator - 1)
              }
              className="btn-after-before bg-primary-custom-text p-4"
            >
              Volver
            </button>
            <button className="btn-save hidden" disabled>
              Guardar
            </button>
            <button
              onClick={() =>
                controllator < 4
                  ? setControllator(controllator + 1)
                  : handleCheckout()
              }
              className="btn-after-before bg-primary-custom-text p-4"
            >
              Siguiente
            </button>
          </section>
        </section>
        {dataTemplate.content ? (
          <div className="contract-section bg-blue-300">
            <div
              className="contract-content"
              dangerouslySetInnerHTML={{
                __html: renderContract().replace(/\n/g, '<br />'),
              }}
            />
          </div>
        ) : (
          <div className="contract-section bg-blue-300">
            <span className="border border-blue-300 shadow bg-[#172b8545] h-[auto] rounded-md p-4 w-full mx-auto">
              <section className="animate-pulse flex space-x-4 flex-col items-center">
                <div className="rounded-full bg-slate-700 h-10 w-10 mb-4"></div>
                <section className="flex-1 space-y-6 py-1">
                  <div className="h-2 bg-slate-700 rounded"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                      <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                    </div>
                    <div className="h-2 bg-slate-700 rounded"></div>
                  </div>
                </section>
              </section>
            </span>
          </div>
        )}
      </div>
    </section>
  );
};
