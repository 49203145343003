import React, { useState, useRef, useEffect } from 'react';
import { User } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const UserMenu = ({ onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 text-gray-600 hover:text-blue-600 transition border-none bg-white cursor-pointer"
      >
        <User className="h-6 w-6" />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-50">
          <button
            onClick={() => {
              setIsOpen(false);
              navigate('/profile');
            }}
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-primary-custom-text cursor-pointer border-none bg-white"
          >
            Perfil
          </button>
          <div className="w-[100%] h-1 bg-[#f2f2f2]"></div>
          <button
            onClick={() => {
              setIsOpen(false);
              navigate('/dashboard');
            }}
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-primary-custom-text cursor-pointer border-none bg-white"
          >
            Dashboard
          </button>
          <div className="w-[100%] h-1 bg-[#ececec]"></div>
          <button
            onClick={() => {
              setIsOpen(false);
              onLogout();
            }}
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-primary-custom-text cursor-pointer border-none bg-white"
          >
            Cerrar sesión
          </button>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
