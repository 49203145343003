import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
const apiUrl = process.env.REACT_APP_API_URL;

const UserProfile = () => {
  const [user, setUser] = useState({ contracts: [] });
  const [password, setPassword] = useState('');
  const [showEditForm, setShowEditForm] = useState(false);
  const navigate = useNavigate();

  const token = localStorage.getItem('authToken');

  useEffect(() => {
    if (!token) {
      navigate('/login');
    } else {
      // Fetch user profile
      axios
        .get(`${apiUrl}/users/profile`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setUser(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [token, navigate]);

  // Toggle para mostrar el formulario de edición
  const handleEditInfo = () => {
    setShowEditForm(!showEditForm);
  };

  // Handle para actualizar los datos del usuario
  const handleUpdateProfile = () => {
    axios
      .put(
        `${apiUrl}/users/update-user`,
        {
          name: user.name,
          surname: user.surname,
          password: password,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(() => alert('Perfil actualizado exitosamente!'))
      .catch((err) => console.error(err));
  };

  // Handle para enviar una peticion de reestablecimiento de contraseña
  const handleChangePassword = () => {
    axios
      .post(
        `${apiUrl}/auth/forgot-password`,
        {
          email: user.email,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(() => {
        // Further steps for changing password with token from email
        alert('Solicitud de cambio de contraseña enviada a tu email');
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      <div
        className={`container mx-auto p-6 text-gray-700 pt-32 ${!showEditForm ? 'h-[65vh]' : 'h-[]'}`}
      >
        <h1 className="text-3xl font-bold text-center text-blue-800 mb-6">
          Perfil del Usuario
        </h1>
        <div className="flex flex-col md:flex-row md:space-x-6">
          {/* Profile Information */}
          <div className="w-full md:w-1/2 p-4 border rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-4">
              Información Personal
            </h2>
            <p className="mb-2">
              <strong>Fecha de creación:</strong> {user ? user.createdAt : ''}
            </p>

            <p className="mb-2">
              <strong>Nombre:</strong> {user ? user.name : ''}
            </p>

            <p className="mb-2">
              <strong>Apellido:</strong> {user ? user.surname : ''}
            </p>

            <p className="mb-2">
              <strong>Correo:</strong> {user ? user.email : ''}
            </p>

            <motion.button
              onClick={handleEditInfo}
              className="mt-4 animated-gradient text-white px-8 py-3 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 flex items-center justify-center group border-none"
            >
              {showEditForm ? 'Cerrar Formulario' : 'Actualizar Información'}
            </motion.button>

            {showEditForm && (
              <div className="mt-4 bg-gray-100 p-4 rounded-lg shadow-inner">
                <h3 className="text-xl font-bold mb-2">
                  Formulario de Actualización
                </h3>
                <form className="space-y-4">
                  <div>
                    <label className="block mb-1">Nombre:</label>
                    <input
                      type="text"
                      value={user.name}
                      onChange={(e) =>
                        setUser({ ...user, name: e.target.value })
                      }
                      className="w-full border border-gray-300 p-2 rounded"
                    />
                  </div>
                  <div>
                    <label className="block mb-1">Apellido:</label>
                    <input
                      type="text"
                      value={user.surname}
                      onChange={(e) =>
                        setUser({ ...user, surname: e.target.value })
                      }
                      className="w-full border border-gray-300 p-2 rounded"
                    />
                  </div>
                  <div>
                    <label className="block mb-1">Contraseña:</label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full border border-gray-300 p-2 rounded"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={handleUpdateProfile}
                    className="mt-4 bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                  >
                    Guardar Cambios
                  </button>
                </form>
              </div>
            )}
          </div>

          {/* Password Change */}
          <div className="w-full md:w-1/2 p-4 border rounded-lg shadow-md h-[28vh]">
            <h2 className="text-2xl font-semibold mb-4">Cambiar Contraseña</h2>
            <motion.button
              onClick={handleChangePassword}
              className="mt-4 animated-gradient text-white px-8 py-3 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 flex items-center justify-center group border-none"
            >
              Enviar solicitud de cambio de contraseña
            </motion.button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
