/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Users, Building2 } from 'lucide-react';
import { NavLink, useParams } from 'react-router-dom';
import axios from 'axios';

export default function ContractEditor() {
  const url = useParams();
  const { id, title } = url;
  const isNDA = title.includes('Confidencialidad');
  const apiUrl = process.env.REACT_APP_API_URL;
  const [description, setDescription] = useState('');

  useEffect(() => {
    window.scrollTo({ top: 0 });
    axios.get(`${apiUrl}/templates/${id}`).then((res) => {
      res.status === 200
        ? setDescription(res.data.description)
        : setDescription('Cargando descripción');
    });
  }, []);

  const ndaOptions = [
    {
      id: 1,
      title: 'Persona Física - Persona Física',
      description: 'Acuerdo de confidencialidad entre dos personas físicas.',
      icon: Users,
    },
    {
      id: 2,
      title: 'Persona Moral - Persona Moral',
      description:
        'Acuerdo de confidencialidad entre dos empresas o entidades morales.',
      icon: Building2,
    },
    {
      id: 3,
      title: 'Persona Física - Persona Moral',
      description:
        'Acuerdo de confidencialidad entre una persona física y una empresa o entidad moral.',
      icon: Building2,
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-6 py-24">
        <div className="max-w-4xl mx-auto">
          {/* Steps */}
          <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-6">
              Proceso de Generación
            </h2>
            <div className="grid grid-cols-3 gap-8">
              <div className="text-center">
                <div className="w-10 h-10 bg-blue-100 text-blue-600 rounded-full flex items-center justify-center mx-auto mb-3">
                  <span className="text-sm font-medium">1</span>
                </div>
                <p className="text-sm text-gray-600">
                  Completa la plantilla con tus datos
                </p>
              </div>
              <div className="text-center">
                <div className="w-10 h-10 bg-blue-100 text-blue-600 rounded-full flex items-center justify-center mx-auto mb-3">
                  <span className="text-sm font-medium">2</span>
                </div>
                <p className="text-sm text-gray-600">
                  Revisa la información ingresada
                </p>
              </div>
              <div className="text-center">
                <div className="w-10 h-10 bg-blue-100 text-blue-600 rounded-full flex items-center justify-center mx-auto mb-3">
                  <span className="text-sm font-medium">3</span>
                </div>
                <p className="text-sm text-gray-600">
                  Descarga tu contrato en PDF o WORD
                </p>
              </div>
            </div>
          </div>

          {/* Contract Information */}
          <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
            {isNDA ? (
              <>
                <div className="grid grid-cols-3 gap-4">
                  {ndaOptions.map((option) => (
                    <div
                      key={option.id}
                      className="border flex flex-col justify-between border-gray-200 rounded-lg p-4 hover:border-blue-500 hover:shadow-md transition-all cursor-pointer"
                    >
                      <div className="flex items-center mb-3">
                        <option.icon className="h-6 w-6 text-blue-600 mr-2" />
                        <h3 className="text-sm font-semibold text-gray-900">
                          {option.title}
                        </h3>
                      </div>
                      <p className="text-gray-600 text-xs mb-3">
                        {option.description}
                      </p>
                      <NavLink
                        to={`/contract/${option.id}/${option.title}`}
                        className="w-full bg-blue-50 block text-center text-blue-600 py-2 rounded-lg hover:bg-blue-100 transition font-medium text-sm"
                      >
                        Seleccionar
                      </NavLink>
                    </div>
                  ))}
                </div>
                <section className="flex flex-col mx-auto w-[100%]">
                  <h1 className="text-2xl pt-6 font-semibold text-gray-900 mb-6">
                    {title}
                  </h1>
                  <div className="prose max-w-none text-gray-600">
                    <p className="mb-6 text-justify">{description}</p>
                  </div>
                </section>
              </>
            ) : (
              <>
                <section className="flex flex-col mx-auto w-[100%]">
                  <h1 className="text-2xl pt-6 font-semibold text-gray-900 mb-6">
                    {title}
                  </h1>
                  <div className="prose max-w-none text-gray-600">
                    <p className="mb-6 text-justify">{description}</p>
                  </div>
                </section>
                <NavLink
                  to={`/contract/${id}/${title}`}
                  className="w-[90%] mx-auto text-center block bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition font-medium"
                >
                  Comenzar Edición
                </NavLink>
              </>
            )}
          </div>

          {/* Additional Information */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">
              Información Importante
            </h2>
            <div className="space-y-4 text-gray-600">
              <p>
                • Este contrato está diseñado para proteger información
                confidencial entre las partes involucradas.
              </p>
              <p>
                • El documento cumple con la legislación vigente y ha sido
                revisado por expertos legales.
              </p>
              <p>
                • Una vez completado, podrás descargar el documento en formato
                PDF o WORD.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
