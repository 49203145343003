/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Tag, Plus } from 'lucide-react';

const predefinedTags = [
  'Pendiente de firma',
  'Revisión legal',
  'Urgente',
  'En proceso',
  'Completado',
];

export default function TagSelector({ contract, handleAddTag }) {
  const [isOpen, setIsOpen] = useState(false);
  const [customTag, setCustomTag] = useState('');

  const handleTagSelect = (tag) => {
    // if (!existingTags.includes(tag)) {
    //   onTagAdd(tag);
    // }
    // setIsOpen(false);
  };

  const handleCustomTagAdd = (e) => {
    // e.preventDefault();
    // if (customTag.trim() && !existingTags.includes(customTag.trim())) {
    //   onTagAdd(customTag.trim());
    //   setCustomTag('');
    // }
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-1 text-sm text-gray-600 hover:text-blue-600"
      >
        <Plus className="w-4 h-4" />
        <span>Agregar etiqueta</span>
      </button>

      {isOpen && (
        <div className="absolute z-10 mt-2 w-64 bg-white rounded-lg shadow-lg border border-gray-200">
          <div className="p-3">
            <div className="mb-3">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleAddTag(contract, customTag);
                  return setIsOpen(!isOpen);
                }}
              >
                <input
                  type="text"
                  value={customTag}
                  onChange={(e) => setCustomTag(e.target.value)}
                  placeholder="Nueva etiqueta..."
                  className="w-full px-3 py-1.5 text-sm border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </form>
            </div>
            <div className="space-y-1">
              {predefinedTags.map((tag) => (
                <button
                  key={tag}
                  onClick={() => {
                    handleAddTag(contract, tag);
                    return setIsOpen(!isOpen);
                  }}
                  className={`w-full text-left px-3 py-1.5 text-sm rounded-lg hover:bg-gray-50 flex items-center space-x-2 text-gray-700`}
                  // disabled={existingTags.includes(tag)}
                >
                  <Tag className="w-4 h-4" />
                  <span>{tag}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
