import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { ChevronRight, Tag, Search } from 'lucide-react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const CatalogDashboard = () => {
  const [contracts, setContracts] = useState([]);

  // Función para obtener los contratos desde la API
  useEffect(() => {
    const fetchContracts = async () => {
      try {
        const responseTemplates = await axios.get(`${apiUrl}/templates/`);

        setContracts(responseTemplates.data);
      } catch (error) {
        console.log('Error fetching contract templates:', error);
      }
    };

    fetchContracts();
  }, []);

  return (
    <div className="space-y-6">
      {/* Barra de búsqueda */}
      <div className="bg-white rounded-lg shadow p-4">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="text"
            placeholder="Buscar contratos..."
            className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      </div>

      {/* Grid de contratos */}
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        {contracts.map((contract, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow"
          >
            <div className="flex justify-between items-start mb-4">
              <h3 className="text-lg font-semibold text-gray-900">
                {contract.name}
              </h3>
              {contract.isFree && (
                <span className="px-2 py-1 bg-green-100 text-green-800 text-xs font-medium rounded-full">
                  Gratis
                </span>
              )}
            </div>

            <p className="text-gray-600 mb-4 text-sm">{contract.description}</p>

            {contract.price && !contract.isFree && (
              <div className="flex items-center mb-4">
                <Tag className="h-4 w-4 text-blue-600 mr-2" />
                <span className="text-lg font-semibold text-blue-600">
                  ${contract.price} MXN
                </span>
              </div>
            )}

            <NavLink
              to={`/template/${contract.id}/${contract.name.replaceAll(' ', '-')}`}
              className="inline-flex items-center text-accent-purple hover:text-accent-indigo font-medium transition-colors group"
              onClick={(e) =>
                contract.name !== 'Carta Poder' ? e.preventDefault() : false
              }
            >
              {contract.name !== 'Carta Poder' ? 'Proximamente' : 'Comenzar'}
              <ChevronRight className="ml-1 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
            </NavLink>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default CatalogDashboard;
