import React, { useState } from 'react';
import {
  MessageSquare,
  FileText,
  Users,
  PenTool,
  History,
  FolderOpen,
  ChevronLeft,
  ChevronRight,
} from 'lucide-react';
import ContractsDashboard from './Components/ContractsDashboard';
import SignDocumentsDashboard from './Components/SignDocumentsDashboard';
import ChatIA from './Components/ChatIA';
import TeamDashboard from './Components/TeamDashboard';
import ContractCatalog from './Components/ContractCatalog';

export default function DashboardPage() {
  const [activeSection, setActiveSection] = useState('chat');
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const menuItems = [
    { id: 'chat', icon: MessageSquare, label: 'Chat IA' },
    { id: 'contracts', icon: FileText, label: 'Mis Contratos' },
    // { id: 'sign', icon: PenTool, label: 'Firmar Documentos' },
    // { id: 'users', icon: Users, label: 'Usuarios' },
    // { id: 'history', icon: History, label: 'Historial' },
    // { id: 'shared', icon: FolderOpen, label: 'Compartidos' },
    { id: 'catalog', icon: FolderOpen, label: 'Catalogo' },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="flex h-[calc(100vh-73px)] pt-[4.5rem]">
        {/* Sidebar */}
        <aside
          className={`bg-white border-r border-gray-200 transition-all duration-300 flex flex-col ${
            isSidebarCollapsed ? 'w-16' : 'w-56'
          }`}
        >
          <button
            onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
            className="p-2 text-gray-400 hover:text-gray-600 self-end mr-2 mt-2"
          >
            {isSidebarCollapsed ? (
              <ChevronRight className="h-5 w-5" />
            ) : (
              <ChevronLeft className="h-5 w-5" />
            )}
          </button>
          <nav className="flex-1 py-2">
            {menuItems.map((item) => (
              <button
                key={item.id}
                onClick={() => setActiveSection(item.id)}
                className={`w-full flex items-center px-3 py-2 my-1 mx-2 rounded-lg transition-colors ${
                  activeSection === item.id
                    ? 'bg-blue-50 text-blue-600'
                    : 'text-gray-600 hover:bg-gray-50 hover:text-blue-600'
                }`}
              >
                <item.icon className="h-5 w-5 flex-shrink-0" />
                {!isSidebarCollapsed && (
                  <span className="ml-3 text-sm">{item.label}</span>
                )}
              </button>
            ))}
          </nav>
        </aside>

        {/* Main Content */}
        <main className="flex-1 p-6 overflow-auto">
          {activeSection === 'chat' && <ChatIA />}
          {activeSection === 'contracts' && <ContractsDashboard />}
          {activeSection === 'sign' && <SignDocumentsDashboard />}
          {activeSection === 'users' && <TeamDashboard />}
          {activeSection === 'catalog' && <ContractCatalog />}
          {!['chat', 'contracts', 'sign', 'users', 'catalog'].includes(
            activeSection
          ) && (
            <div className="flex items-center justify-center h-full">
              <p className="text-gray-500">Sección en desarrollo</p>
            </div>
          )}
        </main>
      </div>
    </div>
  );
}
