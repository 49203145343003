import React, { useState, useRef } from 'react';
import {
  Upload,
  Send,
  FileText,
  AlertCircle,
  CheckCircle,
  MessageSquare,
  X,
  ChevronDown,
  ChevronUp,
  Sparkles,
} from 'lucide-react';

export default function ChatIA() {
  const [messages, setMessages] = useState([
    {
      id: '1',
      type: 'ai',
      content:
        '¡Hola! Soy tu asistente legal. Sube un contrato para analizarlo o hazme preguntas sobre temas legales.',
      timestamp: new Date(),
    },
  ]);
  const [message, setMessage] = useState('');
  const [uploadedDoc, setUploadedDoc] = useState(null);
  const [showAnalysis, setShowAnalysis] = useState(false);
  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null);

  const handleFileUpload = (file) => {
    if (file) {
      setUploadedDoc(file);
      // Simular análisis del documento
      const newMessage = {
        id: Date.now().toString(),
        type: 'ai',
        content: `He analizado el documento "${file.name}". Aquí están mis observaciones principales:

1. Estructura del Contrato: El documento sigue una estructura estándar de contrato legal.
2. Cláusulas Clave: Identificadas las secciones principales de confidencialidad y responsabilidades.
3. Posibles Mejoras: Recomiendo revisar la sección 3.2 sobre términos de resolución.

¿Qué aspecto específico te gustaría analizar en detalle?`,
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, newMessage]);
    }
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (!message.trim()) return;

    const newMessage = {
      id: Date.now().toString(),
      type: 'user',
      content: message,
      timestamp: new Date(),
    };

    setMessages((prev) => [...prev, newMessage]);
    setMessage('');

    // Simular respuesta de la IA
    setTimeout(() => {
      const aiResponse = {
        id: (Date.now() + 1).toString(),
        type: 'ai',
        content:
          'Basado en el análisis del contrato, puedo sugerir mejoras específicas para esa sección. ¿Te gustaría que generemos una versión revisada con las modificaciones sugeridas?',
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, aiResponse]);
    }, 1000);
  };

  return (
    <div className="grid grid-cols-2 gap-6 h-[calc(100vh-12rem)]">
      {/* Document Upload and Preview */}
      <div className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col">
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-gray-900 flex items-center">
            <FileText className="h-5 w-5 mr-2 text-blue-600" />
            Documento Legal
          </h2>
        </div>

        <div className="flex-1 p-4 overflow-auto">
          {uploadedDoc ? (
            <div className="space-y-4">
              <div className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                <div className="flex items-center space-x-3">
                  <FileText className="h-6 w-6 text-blue-600" />
                  <div>
                    <p className="font-medium text-gray-900">
                      {uploadedDoc.name}
                    </p>
                    <p className="text-sm text-gray-500">
                      {(uploadedDoc.size / 1024 / 1024).toFixed(2)} MB
                    </p>
                  </div>
                </div>
                <button
                  onClick={() => setUploadedDoc(null)}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>

              <div className="border border-gray-200 rounded-lg p-4">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="font-medium text-gray-900 flex items-center">
                    <Sparkles className="h-5 w-5 mr-2 text-blue-600" />
                    Análisis del Contrato
                  </h3>
                  <button
                    onClick={() => setShowAnalysis(!showAnalysis)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    {showAnalysis ? (
                      <ChevronUp className="h-5 w-5" />
                    ) : (
                      <ChevronDown className="h-5 w-5" />
                    )}
                  </button>
                </div>

                {showAnalysis && (
                  <div className="space-y-4">
                    <div className="p-3 bg-green-50 rounded-lg flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-600 mt-0.5 mr-2" />
                      <div>
                        <p className="font-medium text-green-800">
                          Elementos Correctos
                        </p>
                        <ul className="mt-1 text-sm text-green-700 list-disc list-inside">
                          <li>Estructura general del contrato</li>
                          <li>Cláusulas de confidencialidad</li>
                          <li>Términos de responsabilidad</li>
                        </ul>
                      </div>
                    </div>

                    <div className="p-3 bg-yellow-50 rounded-lg flex items-start">
                      <AlertCircle className="h-5 w-5 text-yellow-600 mt-0.5 mr-2" />
                      <div>
                        <p className="font-medium text-yellow-800">
                          Sugerencias de Mejora
                        </p>
                        <ul className="mt-1 text-sm text-yellow-700 list-disc list-inside">
                          <li>Revisar sección 3.2 sobre resolución</li>
                          <li>Agregar cláusula de fuerza mayor</li>
                          <li>Especificar plazos de notificación</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="h-full flex items-center justify-center">
              <div className="text-center">
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={(e) =>
                    e.target.files && handleFileUpload(e.target.files[0])
                  }
                  className="hidden"
                  accept=".pdf,.doc,.docx"
                />
                <Upload className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                <p className="text-gray-600 mb-2">
                  Arrastra un documento o haz clic para seleccionar
                </p>
                <button
                  onClick={() => fileInputRef.current?.click()}
                  className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  <Upload className="h-4 w-4 mr-2" />
                  Subir Documento
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Chat Interface */}
      <div className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col">
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-gray-900 flex items-center">
            <MessageSquare className="h-5 w-5 mr-2 text-blue-600" />
            Chat con IA
          </h2>
        </div>

        <div
          ref={chatContainerRef}
          className="flex-1 p-4 overflow-y-auto space-y-4"
        >
          {messages.map((msg) => (
            <div
              key={msg.id}
              className={`flex ${msg.type === 'user' ? 'justify-end' : 'justify-start'}`}
            >
              <div
                className={`max-w-[80%] rounded-lg p-3 ${
                  msg.type === 'user'
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-100 text-gray-900'
                }`}
              >
                <p className="text-sm">{msg.content}</p>
                <span className="text-xs opacity-75 mt-1 block">
                  {msg.timestamp.toLocaleTimeString()}
                </span>
              </div>
            </div>
          ))}
        </div>

        <form
          onSubmit={handleSendMessage}
          className="p-4 border-t border-gray-200"
        >
          <div className="flex space-x-2">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Escribe tu mensaje..."
              className="flex-1 px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              <Send className="h-5 w-5" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
