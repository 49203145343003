/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Shield, Eye, EyeOff } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;

export default function RegisterPage() {
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [messageColor, setMessageColor] = useState('');

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    return () => {};
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    fetch(`${apiUrl}/auth/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) =>
        response.json().then((data) => ({ status: response.ok, data }))
      )
      .then((result) => {
        if (result.status) {
          setMessage(
            'Registration successful. A verification email has been sent.'
          );
          setMessageColor('text-green-500');

          setTimeout(() => {
            window.location.href = '/';
          }, 3000);
        } else {
          setMessage('The email provided is already registered.');
          setMessageColor('text-red-500');
          setTimeout(() => {
            setMessage('');
          }, 3000);
        }
      })
      .catch((error) => {
        setMessage('An unexpected error occurred. Please try again later.');
        setMessageColor('text-red-500');
        setTimeout(() => {
          setMessage('');
        }, 3000);
      });
  };

  const navigate = useNavigate();

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setIsSubmitted(true);
  //   // Simular envío de correo de confirmación
  //   setTimeout(() => {
  //     navigate('/');
  //   }, 3000);
  // };

  // if (isSubmitted) {
  //   return (
  //     <div className="min-h-screen bg-gradient-to-br from-blue-50 to-gray-50 flex items-center justify-center">
  //       <div className="bg-white p-8 rounded-xl shadow-lg max-w-md w-full text-center">
  //         <div className="mb-6 text-green-500">
  //           <svg
  //             className="w-16 h-16 mx-auto"
  //             fill="none"
  //             stroke="currentColor"
  //             viewBox="0 0 24 24"
  //           >
  //             <path
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               strokeWidth="2"
  //               d="M5 13l4 4L19 7"
  //             ></path>
  //           </svg>
  //         </div>
  //         <h2 className="text-2xl font-bold text-gray-900 mb-4">
  //           ¡Registro Exitoso!
  //         </h2>
  //         <p className="text-gray-600 mb-6">
  //           Hemos enviado un correo de confirmación a tu dirección de email. Por
  //           favor, revisa tu bandeja de entrada y sigue las instrucciones para
  //           activar tu cuenta.
  //         </p>
  //         <p className="text-sm text-gray-500">
  //           Serás redirigido a la página principal en unos segundos...
  //         </p>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-gray-50 flex justify-center items-center">
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-md mx-auto bg-white rounded-xl shadow-lg p-8">
          <div className="flex items-center justify-center mb-8">
            <Shield className="h-10 w-10 text-blue-600" />
            <span className="ml-2 text-2xl font-bold text-gray-900">
              Contratos Express
            </span>
          </div>

          <h2 className="text-2xl font-bold text-center text-gray-900 mb-8">
            Crear una cuenta
          </h2>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Nombre
              </label>
              <input
                type="text"
                id="name"
                required
                className="w-full px-2 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition"
                placeholder="Tu nombre"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>

            <div>
              <label
                htmlFor="surname"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Apellidos
              </label>
              <input
                type="text"
                id="surname"
                required
                className="w-full px-2 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition"
                placeholder="Tus apellidos"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Correo Electrónico
              </label>
              <input
                type="email"
                id="email"
                required
                className="w-full px-2 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition"
                placeholder="ejemplo@correo.com"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Contraseña
              </label>
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  className="w-full px-2 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition"
                  placeholder="******"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 my-2 right-3 flex items-center border-transparent text-gray-600 hover:text-blue-600 cursor-pointer"
                >
                  {showPassword ? (
                    <EyeOff className="h-5 w-5" />
                  ) : (
                    <Eye className="h-5 w-5" />
                  )}
                </button>
              </div>
            </div>

            {message && (
              <p
                className={`${messageColor} mt-4 text-center text-sm transition-opacity duration-300 ease-in-out`}
                style={{ opacity: message ? 1 : 0 }}
              >
                {message}
              </p>
            )}

            <button
              type="submit"
              onSubmit={handleSubmit}
              className="w-full cursor-pointer border-none bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition font-medium"
            >
              Registrarse
            </button>
          </form>

          <p className="mt-6 text-center text-sm text-gray-600">
            ¿Ya tienes una cuenta?{' '}
            <button
              onClick={() => navigate('/login')}
              className="text-blue-600 hover:text-blue-700 font-medium border-none bg-transparent cursor-pointer"
            >
              Inicia sesión
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}
