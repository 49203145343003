import React, { useRef } from 'react';
import SignaturePad from 'react-signature-canvas';
import { RefreshCw, Download } from 'lucide-react';

export default function SignatureCanvas({ onSave }) {
  const signaturePad = useRef(null);

  const clear = () => {
    if (signaturePad.current) {
      signaturePad.current.clear();
    }
  };

  const save = () => {
    if (signaturePad.current?.isEmpty()) {
      alert('Por favor, añade tu firma antes de guardar');
      return;
    }
    const dataUrl = signaturePad.current
      ?.getTrimmedCanvas()
      .toDataURL('image/png');
    if (dataUrl) {
      onSave(dataUrl);
    }
  };

  return (
    <div className="border border-gray-200 rounded-lg p-4 bg-white">
      <div className="mb-4">
        <h3 className="text-lg font-medium text-gray-900">Firma Digital</h3>
        <p className="text-sm text-gray-500">
          Dibuja tu firma usando el mouse o pantalla táctil
        </p>
      </div>

      <div className="border rounded-lg mb-4 bg-gray-50">
        <SignaturePad
          ref={signaturePad}
          canvasProps={{
            className: 'w-full h-48',
            style: {
              borderRadius: '0.5rem',
              backgroundColor: 'rgb(249, 250, 251)',
            },
          }}
        />
      </div>

      <div className="flex justify-end space-x-3">
        <button
          onClick={clear}
          className="flex items-center px-4 py-2 text-sm text-gray-600 hover:text-gray-900"
        >
          <RefreshCw className="h-4 w-4 mr-2" />
          Limpiar
        </button>
        <button
          onClick={save}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <Download className="h-4 w-4 mr-2" />
          Guardar Firma
        </button>
      </div>
    </div>
  );
}
